html {
  height: 100%;
  width: 100%;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #303030;
}

body::-webkit-scrollbar-thumb {
  background-color: #424242;
}
